import {
  FetchFunction,
  YotpoBottomLine,
  YotpoConfig,
  YotpoError,
  YotpoData,
} from '@/lib/yotpo/types';
import fetch from 'node-fetch';

export class Yotpo {
  private readonly domain: string;
  private readonly appKey: string;
  private readonly appToken: string;
  private readonly customFetch?: FetchFunction;

  constructor(config: YotpoConfig, customFetch?: FetchFunction) {
    this.domain = config.domain;
    this.appKey = config.appKey;
    this.appToken = config.appToken;
    this.customFetch = customFetch;
  }

  private async getApiData(path: string) {
    try {
      const url = `${this.domain}${path}`;
      const response =
        this.customFetch != null
          ? await this.customFetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            })
          : await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });
      if (!response.ok) {
        throw new YotpoError(`An error occurred when fetching ${url}`);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw new YotpoError(
        'An error occurred when getting api data',
        error as Error
      );
    }
  }

  async getBottomLineByProductId(productId: string): Promise<YotpoBottomLine> {
    const path =
      `/v1/widget/${this.appKey}/products/${productId}/reviews.json?` +
      new URLSearchParams({
        utoken: this.appToken,
        page: '1',
        sort: 'votes_up',
      }).toString();

    const data = await this.getApiData(path);

    if (data?.response?.bottomline) {
      return data.response.bottomline;
    } else {
      throw new YotpoError('An error occurred when retrieving bottom line');
    }
  }

  async getReviewsByProductId(
    page: number = 1,
    productId: string
  ): Promise<YotpoData> {
    const path =
      `/v1/widget/${this.appKey}/products/${productId}/reviews.json?` +
      new URLSearchParams({
        utoken: this.appToken,
        page: page.toString(),
        sort: 'votes_up',
      }).toString();

    const data = await this.getApiData(path);

    if (data?.response?.reviews) {
      return {
        reviews: data.response.reviews,
        pagination: data.response.pagination,
      };
    } else {
      throw new YotpoError('An error occurred when retrieving reviews');
    }
  }
}
