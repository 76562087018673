import {
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
} from '@chakra-ui/react';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { iconlib } from '@/lib/Iconlib';

const selectedFeatureStyle = { color: '#FF6900' };

export interface AccordionItemAtomProps {
  title: string;
  titleIcon?: string;
  children?: React.ReactNode;
  showBottomBorder?: boolean;
  showBottomTop?: boolean;
  paddingXsmall?: boolean;
}

const AccordianItemAtom = ({
  title,
  titleIcon,
  children,
  showBottomBorder,
  showBottomTop,
  paddingXsmall,
}: AccordionItemAtomProps) => {
  const Icon = titleIcon ? iconlib[`${titleIcon}`] : null;

  return (
    <AccordionItem
      className={`pt-4 pb-2 lg:pb-5 ${showBottomBorder ? 'border-b-[1px]' : ''}
      ${showBottomTop ? 'border-t-[1px]' : ''}
      
      `}
    >
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton
              className={`px-4 ${paddingXsmall ? 'lg:px-4' : 'lg:px-6'}`}
            >
              {titleIcon ? (
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  className="font-bold text-base"
                >
                  <span className="flex items-center">
                    <Icon
                      size={18}
                      color="#FF6900"
                      className={'mr-2'}
                      data-testid={'certified-button-icon'}
                    />
                    <span
                      style={isExpanded === true ? selectedFeatureStyle : {}}
                    >
                      {title}
                    </span>
                  </span>
                </Box>
              ) : (
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  className="font-bold text-xl mr-2"
                  data-testid={`accordion-item-atom-${title}`}
                >
                  {title}
                </Box>
              )}
              {isExpanded ? (
                <BsChevronUp className="stroke-1" size={24} color={'#ff6900'} />
              ) : (
                <BsChevronDown
                  className="stroke-1"
                  size={24}
                  color={'#ff6900'}
                />
              )}
            </AccordionButton>
          </h2>
          <AccordionPanel className="pt-3 pb-3 text-newBlack">
            {children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default AccordianItemAtom;
