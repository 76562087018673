import { FooterContext, FooterContextProps } from '@/context/FooterContext';
import {
  HeaderContextProps,
  HeaderProductsContext,
} from '@/context/HeaderContext';
import { useEffect, useContext } from 'react';
import { fetchLayoutData } from '@/lib/FetchLayoutData';
import { ErrorContext, ErrorContextProps } from '@/context/ErrorContext';

export default function useLayoutData() {
  const {
    setHeaderProductsData,
    headerProductsData,
    cartHeaderText,
    setCartHeaderText,
    ctaIconImage,
    setCtaIconImage,
  } = useContext<HeaderContextProps>(HeaderProductsContext);
  const {
    setFooterRedirectLinksData,
    footerRedirectLinksData,
    setFooterComponent,
    footerComponent,
    setFooterCopyrightMsg,
    footerCopyrightMsg,
    setFooterData,
    footerData,
    twitterIcon,
    setTwitterIcon,
  } = useContext<FooterContextProps>(FooterContext);
  const { setErrorData, errorData } =
    useContext<ErrorContextProps>(ErrorContext);

  useEffect(() => {
    if (
      !headerProductsData ||
      !footerRedirectLinksData ||
      !footerComponent ||
      !footerCopyrightMsg ||
      !footerData ||
      !cartHeaderText ||
      !errorData ||
      !ctaIconImage ||
      !twitterIcon
    ) {
      const getLayoutData = async () => {
        const layoutDatafetch = await fetchLayoutData();
        setHeaderProductsData(layoutDatafetch.headerData[0]);
        //@ts-ignore
        setFooterData(layoutDatafetch.footerPopularData[0]);
        //@ts-ignore
        setFooterCopyrightMsg(layoutDatafetch.copyrightMessage);
        //@ts-ignore
        setFooterComponent(layoutDatafetch.footerComponentData);
        //@ts-ignore
        setFooterRedirectLinksData(layoutDatafetch.footerRedirectLinksData);
        //@ts-ignore
        setCartHeaderText(layoutDatafetch.cartHeaderText[0]);
        //@ts-ignore
        setErrorData(layoutDatafetch.errorLinks);
        //@ts-ignore
        setCtaIconImage(layoutDatafetch.ctaIconImage);
        //@ts-ignore
        setTwitterIcon(layoutDatafetch.twitterIcon);
        return layoutDatafetch;
      };

      getLayoutData();
    }
  }, [
    headerProductsData,
    setHeaderProductsData,
    footerRedirectLinksData,
    setFooterData,
    footerComponent,
    setFooterCopyrightMsg,
    footerCopyrightMsg,
    setFooterComponent,
    footerData,
    setFooterRedirectLinksData,
    errorData,
    setErrorData,
  ]);
}
