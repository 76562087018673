import { Box, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { ReactNode, useState } from 'react';

const TooltipWithTouch = ({
  children,
  ...restToolTipProps
}: {
  children: ReactNode;
  [key: string]: any;
}) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  return (
    <Tooltip
      isOpen={isLabelOpen}
      bg="#ff6900"
      color="#fff"
      className="max-w-xs m-4 p-2"
      {...restToolTipProps}
    >
      <Box
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(true)}
        className="inline"
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default TooltipWithTouch;
