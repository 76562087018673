import { createContext, PropsWithChildren, useState } from "react";
import { ProductReviewsProps } from "#/globalTypes";

export interface YotpoProductReviewsContextProps {
  productReviewBottomline: ProductReviewsProps[];
  setProductReviewBottomline: React.Dispatch<React.SetStateAction<ProductReviewsProps[]>>;
}

export const YotpoProductReviewsContext = createContext<YotpoProductReviewsContextProps>({} as YotpoProductReviewsContextProps);

const YotpoProductReviewsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [productReviewBottomline, setProductReviewBottomline] = useState<ProductReviewsProps[]>([]);

  return (
    <YotpoProductReviewsContext.Provider value={{ productReviewBottomline, setProductReviewBottomline }}>
      {children}
    </YotpoProductReviewsContext.Provider>
  );
};
export default YotpoProductReviewsProvider;
