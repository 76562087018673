import {
  FaArrowRight,
  FaBoxOpen,
  FaRocket,
  FaShoppingCart,
  FaEllipsisH,
} from 'react-icons/fa';

import {
  BsFillImageFill,
  BsLightningCharge,
  BsEmojiSmile,
  BsFillBox2Fill,
  BsCalendar,
  BsPhone,
  BsTelephone,
  BsEye,
} from 'react-icons/bs';
import { GiTakeMyMoney, GiNetworkBars } from 'react-icons/gi';
import {
  HiOutlineInformationCircle,
  HiOutlineBadgeCheck,
} from 'react-icons/hi';
import { MdOutlineLocalShipping } from 'react-icons/md';
import { AiOutlineCalendar, AiOutlineMail } from 'react-icons/ai';
import { RxAccessibility } from 'react-icons/rx';
import { PiHandWaving, PiMoneyFill } from 'react-icons/pi';
import { LiaHandshake } from 'react-icons/lia';
import { LuArrowBigUp } from 'react-icons/lu';
import { TbSunglasses } from 'react-icons/tb';
import { IoEarthSharp } from 'react-icons/io5';

interface iconlibType {
  [key: string]: any;
}

export const iconlib: iconlibType = {
  FaShoppingCart,
  FaBoxOpen,
  FaRocket,
  FaArrowRight,
  FaArrowUp: LuArrowBigUp,
  FaMoneyBill: PiMoneyFill,
  FaRegHandshake: LiaHandshake,
  BsFillImageFill,
  BsLightningCharge,
  BsFillBox2Fill,
  BsTelephone,
  GiTakeMyMoney,
  HiOutlineInformationCircle,
  MdOutlineWavingHand: PiHandWaving,
  BsEmojiSmile,
  BsSunglasses: TbSunglasses,
  GiEarthAfricaEurope: IoEarthSharp,
  GiNetworkBars,
  AiOutlineCalendar,
  BsCalendar,
  BsPhone,
  MdOutlineLocalShipping,
  HiOutlineBadgeCheck,
  AiOutlineMail,
  RxAccessibility,
  BsEye,
  FaEllipsisH,
};
