import {
  FooterInfoProps,
  HeaderInfoProps,
  CtaIconImage,
  TwitterIcon
} from '#/globalTypes';
import { getHeaderDataContent } from './contentful';

export async function fetchLayoutData() {
  const headerfooterdata = await getHeaderDataContent();
  const footerPopularSearches = headerfooterdata.filter(
    (footerInfo: FooterInfoProps) => footerInfo.footerPopularSearches
  );
  const footerPopularData = footerPopularSearches
    .reverse()
    .map((search: FooterInfoProps) => search.footerPopularSearches);
  const footerComponent = headerfooterdata.filter(
    (footerInfo: FooterInfoProps) => footerInfo.footerComponent
  );
  const footerComponentData = footerComponent
    .reverse()
    .map((data: FooterInfoProps) => data.footerComponent)[0];
  const footerRedirectLinks = headerfooterdata.filter(
    (footerInfo: FooterInfoProps) => footerInfo.footerRedirectLinks
  );
  const redirectLinksData = footerRedirectLinks
    .reverse()
    .map((data: FooterInfoProps) => data.footerRedirectLinks)[0];
  const footerRedirectLinksData =
    redirectLinksData && Object.values(redirectLinksData);
  const footerLegalMsg = headerfooterdata.filter(
    (footerInfo: FooterInfoProps) => footerInfo.copyrightMessage
  );
  const copyrightMessage = footerLegalMsg
    .reverse()
    .map((footerInfo: FooterInfoProps) => footerInfo.copyrightMessage)[0];

  const headerInfo = headerfooterdata.filter((item: HeaderInfoProps) => item.productInfo);
  const headerData = headerInfo.map(
    (item: HeaderInfoProps) => item.productInfo
  );
  const ctaIconImage = headerInfo.map(
    (item: CtaIconImage) => item.ctaIconImage
  );
  const twitterIcon = headerInfo.map(
    (item:TwitterIcon) => item.twitterIcon
  );
  const cartHeaderText = headerfooterdata.map(
    (footerInfo: FooterInfoProps) => footerInfo.cartHeaderText
  );

  const errorComponent = headerfooterdata.filter(
    (footerInfo: FooterInfoProps) => footerInfo.errorLinks
  );
  const errorLinks = errorComponent
    .reverse()
    .map((data: FooterInfoProps) => data.errorLinks)[0];

  return {
    headerData,
    footerPopularData,
    footerComponentData,
    footerRedirectLinksData,
    copyrightMessage,
    cartHeaderText,
    errorLinks,
    ctaIconImage,
    twitterIcon,
  };
}
