import { YotpoBottomLine, YotpoConfig, YotpoData } from '@/lib/yotpo/types';
import { Yotpo } from '@/lib/yotpo/yotpo';
import { cacheClient } from '@/lib/CacheClient';
import { getUrls } from './Urls';

const url = getUrls();
const yotpoConfig: YotpoConfig = {
  appKey: process.env.NEXT_PUBLIC_YOTPO_APP_KEY as string,
  appToken: process.env.NEXT_PUBLIC_YOTPO_UTOKEN as string,
  domain: process.env.NEXT_PUBLIC_YOTPO_DOMAIN as string,
};

export const yotpo = new Yotpo(yotpoConfig);

interface YotpoBottomLineCache {
  [key: string]: YotpoBottomLine;
}

interface YotpoReviewsCache {
  [key: string]: YotpoData;
}

const CACHE_BOTTOM_LINE_KEY = 'yopto-bottom-line';
const CACHE_REVIEWS_KEY = 'yopto-reviews';

export const getYotpoBottomLine = async (
  productId: string
): Promise<YotpoBottomLine> => {
  const cache = (cacheClient.get(CACHE_BOTTOM_LINE_KEY) ||
    {}) as YotpoBottomLineCache;

  if (!cache[productId]) {
    cache[productId] = await yotpo.getBottomLineByProductId(productId);
    cacheClient.set(CACHE_BOTTOM_LINE_KEY, cache);
  }

  return cache[productId] || {};
};

const getCachedReviews: (
  page: number,
  productId: string
) => Promise<YotpoData> = async (page: number, productId: string) => {
  const cache = (cacheClient.get(CACHE_REVIEWS_KEY) || {}) as YotpoReviewsCache;
  const key = page.toString();

  if (!cache[key]) {
    cache[key] = await yotpo.getReviewsByProductId(page, productId);
    cacheClient.set(CACHE_REVIEWS_KEY, cache);
  }

  return cache[key] || [];
};

export const getYotpoReviews = async (
  sku: string,
  requestedPage: number
): Promise<YotpoData> => {
  const reviewsResponse = await getCachedReviews(requestedPage, sku);
  return {
    ...reviewsResponse,
    reviews: reviewsResponse.reviews.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    ),
  };
};
