'use client';
import React from 'react';
import ModalTemplate from '../modals/ModalTemplate';
import { NotifyMeContext } from '../../../context/NotifyMeContext';

export interface NotifyMeSurveyModalProps {
  onClose: () => void;
  handle: string;
}
declare global {
  interface Window {
    SMCX: any[];
  }
}

interface productMapType {
  [key: string]: string;
}

const productMap: productMapType = {
  'iphone-12':
    'tRaiETqnLgj758hTBazgd9_2FyRIM6ktCnl0KO7uyCXe95X9uVHbs92LgQAX_2FS5Ql7',
  'iphone-13':
    'tRaiETqnLgj758hTBazgd2BdH4kFTo_2FrxM8QgRvEguczgoA8UYR_2BcF83DMldFiO0',
  'iphone-14':
    'tRaiETqnLgj758hTBazgd2BdH4kFTo_2FrxM8QgRvEgue2_2FVy_2BNo50SfdcsGbgaGcy',
};

const NotifyMeSurveyModal = () => {
  let { isModalOpen, productHandle, toggleModal } =
    React.useContext(NotifyMeContext);

  if (isModalOpen) {
    const src = `https://widget.surveymonkey.com/collect/website/js/${productMap[productHandle]}.js`;

    const callback = () =>
      (function (t, e, s, n) {
        var o, a, c;
        (t.SMCX = t.SMCX || []),
          e.getElementById(n) ||
            ((o = e.getElementsByTagName(s)),
            (a = o[o.length - 1]),
            (c = e.createElement(s)),
            (c.setAttribute('type', 'text/javascript'),
            (c.setAttribute('async', 'true'),
            (c.id = n),
            (c.setAttribute('src', src),
            document.getElementById('sm-12')?.appendChild(c)))));
      })(window, document, 'script', 'smcx-sdk');

    const modalContentSurveyMonkey = (
      <div
        className="xl:w-[500px] xl:h-[525px] w-full max-w-[500px] min-w-[360px] h-[600px] overflow-hidden"
        id="sm-12"
        style={{ overflow: 'hidden' }}
      />
    );

    return (
      <ModalTemplate
        closeModal={toggleModal}
        handleClickSolidButton={toggleModal}
        modalContent={modalContentSurveyMonkey}
        onOpenCallback={callback}
        setHeight={true}
        setWidth={true}
        showFooter={false}
        showHeaderText={false}
        showModalBody={true}
      />
    );
  } else null;
};

export default NotifyMeSurveyModal;
