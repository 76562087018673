import React from 'react';
import { YotpoBottomLine } from '@/lib/yotpo/types';
import { ReviewStars } from '@/components/atoms/ReviewStars';

interface ReviewAndAverageProps {
  showStringAverage?: boolean;
  reviewsTextStyles: string;
  bottomLine?: YotpoBottomLine;
}

const ReviewAndAverage = ({
  bottomLine,
  reviewsTextStyles,
  showStringAverage = false,
}: ReviewAndAverageProps) => {
  return (
    <>
      <div className="flex flex-col gap-1">
        <ReviewStars
          className={'text-flexThemeBlue'}
          rating={bottomLine?.average_score ?? 0}
          size={12}
        />
        {showStringAverage && (
          <p className="font-medium text-bgDarkGrey mb-2 text-center">
            {bottomLine?.average_score?.toFixed(1)}/5 stars
          </p>
        )}
      </div>
      <p className={reviewsTextStyles}>
        {bottomLine?.total_review ?? 0} Reviews
      </p>
    </>
  );
};

export default React.memo(ReviewAndAverage);
