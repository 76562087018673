import { gtmEvent } from './gtm';

export const addRentalToCartGtm = (handle: string) => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'add rental to cart',
      action: 'button click',
      label: 'add rental',
      value: handle,
    },
  });
};

export const subscribedModalGtm = () => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'add rental to cart',
      action: 'button click',
      label: 'subscribed modal',
      value: `already subscribe PDP`,
    },
  });
};

export const replaceRentalGtm = () => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'add rental to cart',
      action: 'link click',
      label: 'replace rental',
      value: 'alreadysubscribed-PDP',
    },
  });
};

export const closeAlreadysubscribedPDPGtm = () => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'add rental to cart',
      action: 'button click',
      label: 'close button',
      value: 'alreadysubscribed-PDP',
    },
  });
};

export const subscribeProductGtm = (handle: string) => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'choose plan',
      action: 'button click',
      label: 'subscribe',
      value: handle,
    },
  });
};

export const visitHelpCenterGtm = () => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'home page visit help center',
      action: 'button click',
      label: 'visit help center',
      value: `1`,
    },
  });
};

export const stickySubscribeGtm = (handle: string) => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'sticky product detail choose plan',
      action: 'button click',
      label: 'subscribe',
      value: handle,
    },
  });
};

export const acknowledgeInEligibleGtm = () => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'acknowledge inEligible',
      action: 'button click',
      label: 'acknowledge inEligible',
      value: `1`,
    },
  });
};

export const planSelectedGtm = (plan: string) => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'sticky home page choose plan',
      action: 'button click',
      label: 'plan selected',
      value: plan,
    },
  });
};

export const planSubscribeGtm = (planSelected: string) => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'sticky home page subscribe',
      action: 'button click',
      label: 'plan subscribe',
      value: planSelected,
    },
  });
};

export const colorSelectionGtm = (handle: string, title: string) => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'choose variant',
      action: 'button click',
      label: 'color selection',
      value: `${handle} - ${title}`,
    },
  });
};

export const pageViewGtm = () => {
  //@ts-ignore
  gtmEvent({
    event: 'pageview',
    page: {
      url: location,
      title: 'Home Page',
    },
  });
};

export const productDetailPageGtm = (handle: string) => {
  //@ts-ignore
  gtmEvent({
    event: 'pageview',
    page: {
      url: location,
      title: `Product Detail Page -  ${handle}`,
    },
  });
};

export const cartPageGtm = () => {
  //@ts-ignore
  gtmEvent({
    event: 'pageview',
    page: {
      url: location,
      title: `Cart Page`,
    },
  });
};

export const checkoutButtonClickGtm = () => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'cart',
      action: 'button click',
      label: 'checkout',
      value: `1`,
    },
  });
};

export const removeItemGtm = (slug: string) => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'cart',
      action: 'button click',
      label: 'remove item',
      value: `${slug}`,
    },
  });
};

export const clickHereButtonGtm = () => {
  //@ts-ignore
  gtmEvent({
    event: 'event',
    eventProps: {
      category: 'cart',
      action: 'button click',
      label: 'click here',
      value: 'direct-store-link-cart',
    },
  });
};
