
import {
  SectionDataProps,
  HelpCenter,
  LinksDataProps,
  checkoutDataProps,
  FooterInfoProps,
  ContentLinks,
  SubscriptionConfirmation,
  ProductDetails,
  HomePageDataProps,
  ContentParams,
} from '#/globalTypes';
import { Params } from './types';
import logger from '@/lib/logger';

export const contentful = require('contentful');

 const contentfulClient = contentful.createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID as string || 'f5JVItdrhxA6NemcUVxEtie8hg_2Lq2uM9NfJxaHayU' ,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN as string || '85nn39uwj9q8',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT as string || 'dev',
});

async function getByContentType(contentType: string, include?: number) {
  try {
    return await contentfulClient?.getEntries({
      content_type: contentType,
      include: include,
    });
  } catch (error: any) {
    logger.error(error);
    return '';
  }
}

async function getFirstItemFields({ contentType, include }: ContentParams) {
  try{
  const content = await getByContentType(contentType, include);
  return content?.items[0].fields;
  }
  catch(error:any){
    logger.error(error)
    return ''
  }
}

async function getAllItemFields({ contentType, include }: ContentParams) {
  try{
  const content = await getByContentType(contentType, include);
  return content.items.map((item: SectionDataProps) => item.fields);
  }
  catch(error:any){
    logger.error(error)
    return ''
  }
}

export async function getHomePageDataContent(): Promise<HomePageDataProps> {
  return await getFirstItemFields({ contentType: 'homePageData', include: 3 });
}

export async function getLinksContent(): Promise<LinksDataProps> {
  const LinksData = await getFirstItemFields({ contentType: 'contentLinks' });
  return LinksData?.links;
}

export async function getCheckoutPageContent(): Promise<checkoutDataProps> {
  return await getFirstItemFields({ contentType: 'checkoutPage' });
}

export async function getHelpCenterContactUsSectionContent(): Promise<HelpCenter> {
  return await getAllItemFields({ contentType: 'helpCenterContactUsSection' });
}

export async function getHelpCenterHeroSectionContent(): Promise<HelpCenter> {
  return await getAllItemFields({ contentType: 'helpCenterHeroSection' });
}

export async function getHeaderDataContent(): Promise<FooterInfoProps[]> {
  return await getAllItemFields({ contentType: 'headerData' });
}

export async function getContentLinksContent(): Promise<ContentLinks> {
  return await getAllItemFields({ contentType: 'contentLinks' });
}

export async function getSubscriptionConfirmationContent(): Promise<SubscriptionConfirmation> {
  return await getFirstItemFields({ contentType: 'subscriptionConfirmation' });
}

export async function getProductDetailPageContent(
  productSlug: Params
): Promise<ProductDetails> {
  return contentfulClient?.getEntries({
    content_type: 'productDetailPage',
    include: 3,
    'fields.productSlug': productSlug,
  });
}
