import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { tagsToParse } from '@/utilities';
import { initialTags } from '@/constants';
import { ITags } from 'globalTypes';
import { subscribeProductGtm } from '@/lib/Gtmlib';
import ReviewAndAverage from '@/components/molecules/ReviewAndAverage';
import { YotpoBottomLine } from '@/lib/yotpo/types';
import { getYotpoBottomLine } from '@/lib/YotpoClient';
import { PlanCardProps } from './types';
import { MdOutlineMail } from 'react-icons/md';
import { NotifyMeContext } from '../../../context/NotifyMeContext';

const ChoosePlanCard = (props: PlanCardProps) => {
  const {
    bgColor,
    product,
    subscribeButtonText,
    soldOutButtonText,
    cardDescriptionText,
  } = props;

  let { toggleModal, updateProductHandle } = React.useContext(NotifyMeContext);

  const [tags, setTags] = useState<ITags>(initialTags);
  const [bottomLine, setBottomLine] = useState<YotpoBottomLine>();

  const { productId, availableForSale, handle } = product;

  useEffect(() => {
    if (productId) {
      const getBottomLine = async () => {
        const bottomLine = await getYotpoBottomLine(productId);
        setBottomLine(bottomLine);
      };

      getBottomLine();
    }
  }, [productId]);

  useEffect(() => {
    setTags(tagsToParse(product.tags));
  }, [product]);

  const handleChoosePlan = () => {
    subscribeProductGtm(product?.handle);
    window.location.href = `/product/${product?.handle}`;
  };

  const toggleNotifyMeHandler = () => {
    updateProductHandle(handle);
    toggleModal();
  };

  const backgroundStyle = availableForSale
    ? bgColor
    : 'bg-[#1E1E1E] opacity-30';

  return (
    <>
      <section className={'h-auto'} data-testid={'choose-plan-card'}>
        <div
          className={`w-full h-10 ${backgroundStyle} flex justify-center items-center rounded-t-xl`}
        >
          <p className={`text-white font-medium text-sm`}>
            {availableForSale ? tags.Trend : 'TEMPORARILY OUT OF STOCK'}
          </p>
        </div>
        <div
          className={
            'w-full h-[calc(100% -64px)] md:h-auto  xl:h-auto xl:min-w-[315px] lg:h-auto px-4 xl:px-6 pb-4 xl:pb-6 pt-2 flex flex-col items-center justify-between bg-white rounded-b-xl'
          }
        >
          <div className="xl:w-[130px] w-[100px]">
            <Image
              src={product?.imageUrl}
              data-tracking-id={`${product?.handle}-home-subscribe-image`}
              alt={'iPhone to be rented out'}
              className="w-full cursor-pointer"
              width={100}
              height={100}
              onClick={
                availableForSale ? handleChoosePlan : toggleNotifyMeHandler
              }
            />
          </div>
          <Link
            href={`/product/${product?.handle}`}
            onClick={
              availableForSale ? handleChoosePlan : toggleNotifyMeHandler
            }
            className="text-black font-bold text-[24px] xl:text-[32px] font-Brandon-Grotesque mt-4"
          >
            {product?.title}
          </Link>
          <p className="font-bold text-[16px] xl:text-[20px]">
            {cardDescriptionText}{' '}
            <span
              className={
                'text-flexThemeBlue font-Brandon-Grotesque font-bold text-[16px] xl:text-[20px]'
              }
              data-tracking-id={`${product.handle}-home-subscribe-price`}
            >
              {'$'}
              {Number(product.price).toFixed(2)}
              {'/mo.'}
            </span>
          </p>
          <div className={'w-full h-px bg-bgLightGrey mt-4'}></div>
          <div
            className={
              'w-[244px] xl:w-full flex flex-row justify-between items-center mt-4'
            }
          >
            <p className={'font-normal text-[16px]'}>{tags?.Carrier}</p>
            <div className={'w-[0.4px] h-4 bg-flexThemeBlue'}></div>
            <p className={'font-normal text-[16px]'}>{tags?.Storage}</p>

            <div
              className={'hidden xl:flex w-[0.4px] h-4 bg-flexThemeBlue'}
            ></div>
            <div className={'hidden xl:flex flex-row'}>
              {/* @//todo dynamic colors */}
              {
                //product?.options[0]?.values.map((color: string) => (<div key={color} className={`w-6 h-6 bg-[${IPHONE_COLOR_HEX[color as keyof IiphoneColorHex]}] rounded-full mr-1`} />))
              }
              <div
                className={
                  'w-5 h-5 bg-white rounded-full border border-black mr-1.5'
                }
              ></div>
              <div className={'w-5 h-5 bg-black rounded-full mr-1.5'}></div>
              <div className={`w-5 h-5 bg-blue rounded-full`}></div>
            </div>
          </div>
          <div className={'xl:hidden flex flex-row mt-4'}>
            {/* @//todo dynamic colors */}
            {
              //product?.options[0]?.values.map((color: string) => (<div key={color} className={`w-6 h-6 bg-[${IPHONE_COLOR_HEX[color as keyof IiphoneColorHex]}] rounded-full mr-1`} />))
            }
            <div
              className={
                'w-5 h-5 bg-white rounded-full border border-black mr-1.5'
              }
            ></div>
            <div className={'w-5 h-5 bg-black rounded-full mr-1.5'}></div>
            <div className={`w-5 h-5 bg-blue rounded-full`}></div>
          </div>
          <div className={'w-full h-px bg-bgLightGrey mt-4'}></div>
          <div
            className={'w-full flex flex-row justify-center items-center mt-4'}
          >
            <ReviewAndAverage
              reviewsTextStyles={'ml-2 font-normal text-[16px]'}
              bottomLine={bottomLine}
            />
          </div>
          <div className={'w-full h-px bg-bgLightGrey mt-4'}></div>
          <div className={'w-full mt-4 text-center xl:mt-6'}>
            <button
              onClick={
                availableForSale ? handleChoosePlan : toggleNotifyMeHandler
              }
              type="button"
              className={
                'w-full text-small font-bold text-white bg-primary hover:bg-hoverPrimary focus:ring-4 focus:outline-none focus:ring-white rounded-md px-5 py-2.5 text-center disabledButton'
              }
              data-tracking-id={`${product?.handle}-home-subscribe-button`}
            >
              {availableForSale ? (
                subscribeButtonText
              ) : (
                <div className="flex flex-row justify-center items-center">
                  <MdOutlineMail size={20} className={'mr-2'} />
                  <div>Notify Me When Available</div>
                </div>
              )}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(ChoosePlanCard);
