import { FaStar } from 'react-icons/fa';
import { AiOutlineStar } from 'react-icons/ai';
import React from 'react';

interface ReviewStarsProps {
  rating: number;
  size: number;
  color?: string;
  className?: string;
}

export const ReviewStars = ({
  rating,
  size,
  color,
  className,
}: ReviewStarsProps) => {
  const stars = [...Array(5).fill(0)].map((_, i) => (
    <div key={i}>
      {i + 1 <= Math.floor(rating) ? (
        <FaStar color={color} className={className} size={size} />
      ) : (
        <AiOutlineStar color={color} className={className} size={size} />
      )}
    </div>
  ));
  return <div className="flex flex-row gap-1">{stars}</div>;
};
