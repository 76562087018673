import { ContentContextProps, ContentContext } from '@/context/ContentContext';
import { useEffect, useContext } from 'react';
import { getLinksContent, getCheckoutPageContent } from "@/lib/contentful";

export default function useFetchContent() {
  const { setLinksData, linksData, setCheckoutData, checkoutData } =
    useContext<ContentContextProps>(ContentContext);
  useEffect(() => {
    if (!linksData?.logo?.link || !checkoutData) {
      const getLinksData = async () => {
        const linkDatafetch = await getLinksContent();
        setLinksData(linkDatafetch);
        const checkoutDatafetch = await getCheckoutPageContent();
        setCheckoutData(checkoutDatafetch);
        return { linkDatafetch, checkoutDatafetch };
      };
      getLinksData();
    }
  }, [linksData, setLinksData, checkoutData, setCheckoutData]);
}
