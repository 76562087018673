export type FetchFunction = (
  input: RequestInfo | URL,
  init?: RequestInit
) => Promise<Response>;

export interface YotpoConfig {
  appKey: string;
  appToken: string;
  domain: string;
}

export interface YotpoBottomLine {
  average_score: number;
  custom_fields_bottomline?: string;
  organic_average_score?: number;
  star_distribution?: string;
  total_organic_reviews?: number;
  total_review?: string;
}

export interface YotpoReview {
  archived: boolean;
  content: string;
  created_at: string;
  deleted: boolean;
  email: string;
  escalated: boolean;
  id: number;
  is_incentivized: boolean;
  user: {
    display_name: string;
  };
  reviewer_type: string;
  score: number;
  sentiment: number;
  sku: string;
  title: string;
  updated_at: string;
  votes_down: number;
  votes_up: number;
}

export interface YotpoPagination {
  page: number;
  per_page: number;
  total: number;
}

export interface YotpoData {
  reviews: YotpoReview[];
  pagination: YotpoPagination;
}

export class YotpoError extends Error {
  innerError?: Error;

  constructor(message: string, innerError?: Error) {
    super(message);
    this.name = 'YotpoError';
    this.innerError = innerError;
  }
}
