import NotifyMeSurveyModal from '@/components/organisms/notifyMeModal/NotifyMeSurveyModal';
import React, { createContext, PropsWithChildren, useState } from 'react';

export interface NotifyMeContextProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  productHandle: string;
  updateProductHandle: (productHandle: string) => void;
}

export const NotifyMeContext = createContext<NotifyMeContextProps>(
  {} as NotifyMeContextProps
);

const NotifyMeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [productHandle, setProductHandle] = useState<string>('');

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const updateProductHandle = (productHandle: string) => {
    setProductHandle(productHandle);
  };

  return (
    <NotifyMeContext.Provider
      value={{ isModalOpen, toggleModal, productHandle, updateProductHandle }}
    >
      {isModalOpen && <NotifyMeSurveyModal></NotifyMeSurveyModal>}
      {children}
    </NotifyMeContext.Provider>
  );
};
export default NotifyMeProvider;
