'use client';
import React, { useEffect } from 'react';
import { CloseButton } from '@chakra-ui/react';
import { ModalTemplateProps } from './types';

export default function ModalTemplate(props: ModalTemplateProps) {
  const {
    showHeaderText,
    isHeaderCenteredOnMobile,
    isSmallHeaderOnMobile,
    headerText,
    modalContent,
    onOpenCallback,
    showFooter,
    closeModal,
    showModalBody,
    showOutlineButton,
    showSolidButton,
    outlineButtonText,
    solidButtonText,
    isButtonRight,
    setHeight,
    setWidth,
    width,
    height,
    fullButtonWidth,
    dataTrackingId,
    handleClickSolidButton,
    handleClickOutlineButton,
  } = props;

  const calculatedWidth = setWidth ? width : 'w-auto';
  const calculatedHeight = setHeight ? height : 'h-auto';

  useEffect(() => {
    if (onOpenCallback) onOpenCallback();
  }, [onOpenCallback]);

  return (
    <div className="fixed top-0 left-0 right-0 z-100 w-full p-4 overflow-x-hidden overflow-y-auto xl:inset-0 h-[calc(100%-1rem)] max-h-full bg-fontGrey bg-opacity-50 flex justify-center">
      <div className="relative xl:max-h-full">
        <div
          className={`relative bg-white rounded-lg shadow ${calculatedWidth} ${calculatedHeight}`}
        >
          {/* Modal header */}
          <div className="flex items-start font-medium justify-center p-3 xl:p-8 rounded-t">
            {showHeaderText && (
              <h1
                className={`xl:text-left ${
                  isSmallHeaderOnMobile
                    ? 'text-xl xl:text-3xl font-medium'
                    : 'text-xl xl:text-3xl font-medium'
                } ${
                  isHeaderCenteredOnMobile ? 'text-center' : 'text-left'
                } mr-4`}
              >
                {headerText}
              </h1>
            )}
            <button
              type="button"
              className="text-bgDarkGrey bg-transparent hover:bg-fontGrey font-bold rounded-lg text-2xl ml-auto inline-flex items-center"
              data-tracking-id={dataTrackingId}
              onClick={() => closeModal(false)}
            >
              <CloseButton padding={'5px'} />

              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          {showModalBody && (
            <div className="p-4 pt-2 pb-2 xl:p-8 xl:pt-0 space-y-6">
              {modalContent}
            </div>
          )}
          {/* Modal footer */}
          {showFooter && (
            <div
              className={`flex items-right ${
                isButtonRight ? 'justify-end' : null
              } xl:p-6 p-0 space-x-2 rounded-b`}
            >
              {showOutlineButton && (
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={handleClickOutlineButton}
                  className={`text-primary bg-white hover:bg-fontGrey hover:text-white focus:ring-4 focus:outline-none focus:ring-primary rounded-lg border border-primary text-xl font-medium px-5 py-2.5 focus:z-10 ${
                    fullButtonWidth ? 'w-full' : 'w-auto'
                  }`}
                >
                  {outlineButtonText}
                </button>
              )}
              {showSolidButton && (
                <button
                  data-modal-hide="defaultModal"
                  onClick={handleClickSolidButton}
                  type="button"
                  className={`text-white bg-primary hover:bg-hoverPrimary focus:ring-4 focus:outline-none focus:ring-white font-medium rounded-lg text-xl px-5 py-2.5 text-center ${
                    fullButtonWidth ? 'w-full' : 'w-auto'
                  }`}
                >
                  {solidButtonText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
